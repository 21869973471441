<template>
  <a-upload
    :multiple="false"
    accept=".jpg, .jpeg, .png"
    list-type="picture-card"
    :disabled="readOnly"
    :file-list="fileList"
    :show-upload-list="false"
    :custom-request="customRequest"
    :beforeUpload="beforeUpload"
    @change="changeFile"
  >
    <img v-if="pic" :src="pic" alt="封面" class="cover-img"/>
    <div v-else>
      <a-icon :type="loading ? 'loading' : 'plus'"/>
      <div class="ant-upload-text">上传</div>
    </div>
  </a-upload>
</template>

<script>
// import { uploadPic } from '@/api/Common';
import { commonUpload } from '@/api/config';

export default {
  name: 'upLoadPic',
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      require: true,
    },
    height: {
      type: Number,
      require: true,
    },
    minWidth: {
      type: Number,
      require: true,
    },
    minHeight: {
      type: Number,
      require: true,
    },
    rate: {
      type: String,
      require: true,
    },
    imgUrl: {
      type: String,
      default: () => '',
    },
  },
  watch: {
    imgUrl(val) {
      if (val) {
        this.pic = val;
      }
    },
  },
  data() {
    return {
      pic: '',
      loading: false,
      fileList: undefined,
    };
  },
  methods: {
    customRequest({ file }) {
      this.uploadPicOvert(file);
    },
    async uploadPicOvert(file) {
      const formData = new FormData();
      formData.append('file', file);
      const data = await commonUpload(formData);
      this.pic = data?.data?.url || '';
      this.$emit('success', data.data);
      this.$emit('uploadLoainng', false);
    },
    beforeUpload(file) {
      const types = ['jpg', 'jpeg', 'png'];
      const type = file.type;
      const size = file.size;
      const rate = this.rate;
      const width = this.width;
      const height = this.height;
      const minWidth = this.minWidth;
      const minHeight = this.minHeight;
      return new Promise((resolve, reject) => {
        if (types.every((t) => !type.includes(t))) {
          this.$message.info('图片仅支持 .jpg .jpeg .png 格式，请重新选择图片');
          return reject('ImageTypeError');
        }
        if (size > 1024 * 1024) {
          this.$message.info('图片大小不能超过 1M，请重新选择图片');
          return reject('ImageSizeError');
        }
        const imgUrl = URL.createObjectURL(file);
        const imgEl = new Image();
        imgEl.src = imgUrl;
        imgEl.onload = () => {
          const imgRate = (imgEl.width / (imgEl.height || 1)).toFixed(1);
          if (width || height) {
            if (
              rate !== imgRate &&
              width !== imgEl.width &&
              height !== imgEl.height
            ) {
              this.$message.info(
                `图片支持尺寸为：${width}*${height}，请重新选择图片！`,
              );
              return reject('ImageRateError');
            }
          } else {
            if (
              rate !== imgRate &&
              minWidth > imgEl.width &&
              minHeight > imgEl.height
            ) {
              this.$message.info(
                `图片支持最小尺寸为：${minWidth}*${minHeight}，请重新选择图片！`,
              );
              return reject('ImageRateError');
            }
          }

          return resolve();
        };
      });
    },
    changeFile(info) {
      if (info.file.status === 'uploading') {
        this.$emit('uploadLoainng', true);
      }
    },
  },
  mounted() {
    if (this.imgUrl) {
      this.pic = this.imgUrl;
    }
  },
};
</script>

<style scoped>
.cover-img {
  max-width: 104px;
  max-height: 104px;
}
</style>
